import { render, staticRenderFns } from "./UsersMailsSelector.vue?vue&type=template&id=2ba8e149&"
import script from "./UsersMailsSelector.vue?vue&type=script&lang=js&"
export * from "./UsersMailsSelector.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports